import React from "react"

import './Footer.css'

class Footer extends React.Component {
  render = () =>
    <div className={'footerContainer'}>
      <span className={'footerLegal'}>
        © Todos los derechos reservados
      </span>
      <a href='/privacy.pdf' className={'footerLegal'}>
        Política de privacidad
      </a>
      <a href='/cookies.pdf' className={'footerLegal'}>
        Política de cookies
      </a>
      <a href='/tos.pdf' className={'footerLegal'}>
        Términos y condiciones
      </a>
      <a href='https://blog.recitaleyes.com/' className={'footerLegal'}>
        Blog
      </a>
    </div>
}

export default Footer